import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'


export default () => (
	<StaticQuery 
		query = {
			graphql`
			query {
				IntellectLogo: file(relativePath: { eq: "INTELLECT_LOGO.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
					...GatsbyImageSharpFluid
					}
				}
				}
			}
			`
		}
	
		render = { data => (
			<Img fluid={data.IntellectLogo.childImageSharp.fluid}
				overlayColor="#04040454"
				// height="5vh"
				// width="5vh"
				alt='Intellect Logo'
				style={{
					// position: 'absolute',
					// top: '0',
					// left: '0',
					transition: 'opacity 0.5s',
					transitionDelay: '0.5s',
					opacity: '0.5',
					width: '100%',
					height: '100%',
					maxHeight: 'calc(50vh - 4rem)',
					// margin: '1rem',
					objectFit: 'contain',				// none, fill, contain, cover, scale-down 
					objectPosition: 'right'
				}}
				imgStyle={{ objectFit: 'contain' }} 	// none, fill, contain, cover, scale-down 
			/>

		)}
	/>	
);	
	
/*
	<Img fluid={props.data.IntellectLogo.childImageSharp.fluid}
		alt='Intellect Logo'
		style={{
				position: 'absolute',
				top: '0',
				left: '0',
				transition: 'opacity 0.5s',
				transitionDelay: '0.5s',
				opacity: '1',
				width: '50%',
				height: '50%',
				maxHeight: 'calc(50vh - 4rem)',
				margin: '1rem',
				objectFit: 'cover',
				objectPosition: 'center'
		}}
		imgStyle={{ objectFit: 'contain' }}
	/> 
*/
