import React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Paper from '@material-ui/core/Paper'
import { withStyles } from '@material-ui/core/styles'
import cssAll from './contact.css'
import IntellectLogo from '../../StaticQueries/IntellectLogo'
import ContactHeader from '../../StaticQueries/ContactHeader'
// import { navigate } from 'gatsby-link'
// import Recaptcha from 'react-google-recaptcha'


//import Typography from '@material-ui/core/Typography'
import Typography from 'typography'   // Gatsby Typography
import irvingTheme from 'typography-theme-irving'

const typography = new Typography(irvingTheme)


// [TOLI, 20191125]
// The 'netlify-provided' reCaptcha has known issues with React.
//      data-netlify-recaptcha="true"
//      <div data-netlify-recaptcha />
// Use the generic Google-provided reCaptcha instead...
// import Recaptcha from 'react-google-recaptcha'

const styles = theme => ({
    paper: {
        maxWidth: 1700,
        margin: 'auto',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            minWidth: 600,
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: 936,
        },
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing.unit,
    },
    contentWrapper: {
        margin: '40px 16px',
        // height: 368,
    },
    container: {
        padding: '48px 36px 48px',
    }
})

const RECAPTCHA_KEY = process.env.SITE_RECAPTCHA_KEY
const RECAPTCHA_SECRET = process.env.SITE_RECAPTCHA_SECRET

var reCaptchaValue = ''
var reCaptchcaServerValidation = false
const reCaptchaRef = React.createRef()

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&")
}

class ContactForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            canSubmit: false
        }
    }


    handleChange = e => {
        this.setState({ [e.target.name]: e.target.value })
        // console.log("---> e.target.name: " + e.target.name + ", e.target.value: " + e.target.value)
    }


    handleRecaptcha = value => {
        // console.log("'g-recaptcha-response' value: " + value)
        this.setState({ "g-recaptcha-response": value })
        reCaptchaValue = reCaptchaRef.current.getValue()

        // Client-side validation
        if (reCaptchaValue.length == 0) {
            this.setState({ "canSubmit": false })
            // grecaptcha.reset()
            alert("Please verify that you are human!")
            return false
        }

        try {
            (async () => {
                const response = await fetch('https://serene-caverns-60951.herokuapp.com/https://www.google.com/recaptcha/api/siteverify' + '?secret=' + RECAPTCHA_SECRET + '&response=' + reCaptchaValue);
                const myJson = await response.json();
                // console.log(JSON.stringify(myJson));

                if (myJson.success) {
                    this.setState({ "canSubmit": true })
                    console.log("Successfully Validated? " + myJson.success + " [Challenge_TS: " + myJson.challenge_ts + ", Hostname: " + myJson.hostname + "]")
                }
            })();
        } catch (error) {
            console.error(error);
            grecaptcha.reset()
        }
    }


    // handleSubmit = e => {
    //     e.preventDefault()

    //     if (reCaptchaValue.length == 0) {
    //         //reCaptcha not verified
    //         // grecaptcha.reset()
    //         alert("Please verify that you are human!")
    //         return false
    //     }

    //     // if (!reCaptchcaServerValidation) {
    //     //     alert("Failed to validate that you are human!")
    //     //     return false
    //     // }

    //     const form = e.target

    //     fetch("/", {
    //         method: "POST",
    //         headers: { "Content-Type": "application/x-www-form-urlencoded" },
    //         body: encode({
    //             "form-name": form.getAttribute("name"),
    //             ...this.state
    //         })
    //     })
    //         .then(() => navigate(form.getAttribute("action")))
    //         .catch(error => alert(error))

    //     // reCaptchaValue = ''
    // }


    render() {
        return (
            <section id="contact">
                <div>
                    <form className="contactForm" name="contact" action="/success" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" enctype="multipart/form-data">
                        {/* We need to add the hidden input with the form name to our JSX form
                        https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#step-2 */}
                        <input type="hidden" name="form-name" value="contact" />

                        <noscript>
                            <p>This form won’t work with Javascript disabled</p>
                        </noscript>

                        <div className="container">
                            <ul style={{ listStyleType: 'none' }}>
                                <li>
                                    <select>
                                        <option selected disabled>-- Please choose an option --</option>
                                        <option>Discuss A Project</option>
                                        <option>Get A Quote For Development</option>
                                        <option>Send A File</option>
                                        <option>Other</option>
                                    </select>
                                </li>
                                <li>
                                    <div className="grid grid-2">
                                        <input name="FirstName" type="text" placeholder="Name" required />
                                        <input name="LastName" type="text" placeholder="Surname" required />
                                    </div>
                                </li>
                                <li>
                                    <div className="grid grid-2">
                                        <input name="EMail" type="email" placeholder="Email" required />
                                        <input name="Phone" type="tel" placeholder="Phone" />
                                    </div>
                                </li>
                                <li>
                                    <textarea name="Message" placeholder="Message" defaultValue={""} />
                                </li>
                                <li>
                                    <div class="field">
                                        <input type="file" name="UploadFile" id="UploadFile" placeholder="Upload File" />
                                    </div>
                                </li>

                                {/* <div align="right">
                                    <Recaptcha
                                        ref={reCaptchaRef}
                                        sitekey={RECAPTCHA_KEY}
                                        onChange={this.handleRecaptcha}
                                    />
                                    <br />
                                </div> */}

                                <li>
                                    <div className="grid grid-3">
                                        <div className="required-msg">REQUIRED FIELDS</div>

                                        <button className="btn-grid" type="submit" enabled>
                                            <span className="back">
                                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/email-icon.svg" alt />
                                            </span>
                                            <span className="front">SUBMIT</span>
                                        </button>

                                        <button className="btn-grid" type="reset" enabled>
                                            <span className="back">
                                                <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/eraser-icon.svg" alt />
                                            </span>
                                            <span className="front">RESET</span>
                                        </button>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </form>
                    <footer>
                        <div className="container">
                            {/* <small>
                                 Made with <span>❤</span> by <a href="http:gr8horizons.com" target="_blank">ABS</a>
                             </small> */}
                        </div>
                    </footer>
                </div>
            </section >
        );
    }
}


function ContactContent({ classes }) {
    return (
        <>
            <div className={classes.container}>
                <Paper className={classes.paper}>

                    <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                        {/* <h1 align="center" style={{ color: 'green' }}>-= G°E°T ≡≡≡ I°N ≡≡≡ T°O°U°C°H°! =-</h1> */}
                        <ContactHeader />
                    </AppBar>

                    <div className={classes.contentWrapper}>
                        {/* <Typography color="textSecondary" align="justify"> */}
                            <IntellectLogo />
                        {/* </Typography> */}
                    </div>

                    <div className={classes.contentWrapper}>
                        {/* <Typography color="textSecondary" align="justify"> */}
                        <typography color="textPrimary" align="justify">
                            <ContactForm />
                            <hr />

                            <div align="center" style={{fontSize: '120%', color: 'blue'}}>
                                {/* <table>
                                    <tr>
                                        <td>
                                            <h3><span style={{ color: "green" }}>&#9993;</span> <span style={{ color: "blue" }}><a href="#">toli@gr8horizons.com</a></span><span style={{ color: "red" }}> | </span></h3>
                                        </td>

                                        <td>
                                            <h3><span style={{ color: "green" }}>&#9742;</span> <span style={{ color: "blue" }}>+1 (416) 455-2944</span><span style={{ color: "red" }}> | </span></h3>
                                        </td>

                                        <td>
                                            <h3><span style={{ color: "green" }}>&#127968;</span> <span style={{ color: 'blue' }}>4 Kingshill Rd., Richmond Hill, ON L4E 4B1, Canada</span></h3>
                                        </td>
                                    </tr>
                                </table> */}

                                {/* <h3> */}
                                    <span style={{ color: "blue" }}>The Contact Form above is provided for communications, in lieu of the traditional </span>
                                    <span style={{ color: "red" }}> [</span>
                                    <span style={{ color: "green" }}>&#9993;</span>
                                    <span style={{ color: "blue" }}> <a href="#"> e-mail</a></span>
                                    <span style={{ color: "red" }}> | </span>
                                    <span style={{ color: "green" }}>&#9742;</span>
                                    <span style={{ color: "blue" }}> Phone Number</span>
                                    <span style={{ color: "red" }}> | </span>
                                    <span style={{ color: "green" }}>&#127968;</span>
                                    <span style={{ color: 'blue' }}> Company Address</span>
                                    <span style={{ color: "red" }}>] </span>
                                    <span style={{ color: "blue" }}> information</span>
                                {/* </h3> */}
                            </div>
                        </typography>
                        {/* </Typography> */}
                    </div>
                </Paper>
            </div>
        </>
    )
}

ContactContent.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContactContent)

/*
    <Img fluid={props.data.IntellectLogo.childImageSharp.fluid}
		alt='Intellect Logo'
		style={{
				position: 'absolute',
				top: '0',
				left: '0',
				transition: 'opacity 0.5s',
				transitionDelay: '0.5s',
				opacity: '1',
				width: '50%',
				height: '50%',
				maxHeight: 'calc(50vh - 4rem)',
				margin: '1rem',
				objectFit: 'cover',
				objectPosition: 'center'
		}}
		imgStyle={{ objectFit: 'contain' }}
    />
*/
